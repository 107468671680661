// Style 1
.sticky-top {
    position: sticky;
}

.trans-nav {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;

    &.trans-top {
        .header-default {
            background: transparent;

            .navbar-nav {
                .nav-link {
                    color: white;

                    &:hover {
                        color: $accent;
                    }
                }

                .nav-item {
                    a {
                        color: white;
                    }

                    i,
                    svg {
                        color: white;
                    }
                }
            }
        }
    }
}

.shrink-on-scroll {
    &.nav-shrink {
        .header-default {
            .theme-logo {
                transition: all 100ms linear;
                max-height: 50px;
            }
        }
    }
}

.reveal-phone {
    cursor: pointer;
}

.hidden-phone {
    background: #fff;
    display: none;
    padding: 15px;
    border-radius: 5px;
    position: absolute;
    top: 30px;
    width: 200px;
    z-index: 99999;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    right: 0px;
    text-align: center;

    &:before {
        content: "";
        width: 0px;
        height: 0px;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #fff;
        position: absolute;
        top: -7px;
        left: 0px;
        right: 0px;
        margin: auto;
        position: absolute;
    }
}

@media (min-width: 1500px) {
    /*.header-style-1-topbar {
        background: linear-gradient(60deg, #003c6e 66%, #f03e44 66%)!important;
    }*/
}

.header-default {
    background: #fff;
    box-shadow: 0px 7px 15px -15px rgba(0, 0, 0, 0.15);
    padding-top: 0px;
    padding-bottom: 0px;
    z-index: 10001;
    width: 100%;
    flex-wrap: nowrap;
    transition: all 350ms ease-in-out;

    .show .navbar-nav {
        height: calc(100vh - 150px);
        overflow: scroll;

        @include media-breakpoint-down(sm) {
            padding-bottom: 200px !important;
        }

        @include media-breakpoint-up(xl) {
            height: auto;
            padding-bottom: 0px !important;
        }
    }

    .row.header-style-1-contain-row {
        width: calc(100% + 30px);
    }

    .theme-logo {
        max-height: 70px;
        width: auto;
        transition: all 350ms ease-in-out;

        &:hover {
            opacity: 1;
        }

        @include media-breakpoint-up(lg) {
            max-width: 100%;
        }
    }

    li.dropdown:last-child {
        @include media-breakpoint-up(xl) {
            .dropdown-menu {
                right: 0px;
                left: auto;
            }

            .dropdown ul.dropdown-menu {
                right: auto;
                left: -100% !important;
            }
        }
    }

    .navbar-nav .nav-link {
        color: #000;
        transition-duration: 350ms;
        font-family: $menu-font;
        outline: none !important;
        padding: 5px 15px;
        font-weight: $medium-weight;

        &:hover,
        &:focus {
            opacity: 0.8;
            color: $brand-primary;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 0.5em;
            padding-right: 0.5em;
            font-size: 18px;
            letter-spacing: 0.05em;
        }

        &.dropdown-toggle::after {
            color: $accent !important;
        }
    }

    .navbar-nav .active .nav-link,
    .navbar-nav .active .nav-link:focus {
        color: $brand-primary;
    }

    .navbar-toggler {
        border: none;
        outline: none !important;
        transition: all 0.2s ease-in;

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0,34,68)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");

            &:focus {
                box-shadow: unset;
            }
        }

        @include media-breakpoint-up(md) {
            top: 25px;
        }
    }

    button.navbar-toggler.ml-auto.collapsed {
        outline: none;
    }

    .dropdown.menu-item-has-children {
        @include media-breakpoint-down(xl) {
            a {
                padding-left: 15px;
            }
        }
    }

    .dropdown-menu {
        width: 100% !important;
        border: none;
        margin-top: 0px;
        transform: none !important;
        min-width: 16rem;
        background: $dark;
        padding: 0px;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;

        @include media-breakpoint-up(xl) {
            position: absolute !important;
            display: block;
            box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.65);
            overflow: visible;
            top: 100% !important;
            transform: rotateX(-75deg);
            transform-origin: 0% 0% !important;
            left: 0;

            &.show {
                transition: 0.3s;
                opacity: 1;
                visibility: visible;
                top: 100% !important;
                transform: rotateX(0deg);
            }
        }

        .nav-item {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            padding: 0px;

            .dropdown-item {
                color: #fff;
                font-size: 14px;
                text-transform: uppercase;
                padding: 0.75rem 1.5rem;
                transition: all 0.2s ease-in;
                font-weight: $medium-weight;
                white-space: normal;

                &:hover {
                    color: $dark;
                    background: $light;
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }

        .dropdown .dropdown-toggle::after {
            position: absolute;
            top: 43%;
            right: 15px;
            -webkit-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);
        }
    }

    @include media-breakpoint-down(xl) {
        .navbar-collapse {
            .navbar-nav {
                text-align: center;
                padding-top: 15px;
                padding-bottom: 15px;
                font-size: 1.1em;

                .nav-item {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }

            .dropdown-menu {
                text-align: center;
                color: $brand-secondary !important;
                box-shadow: none;

                .dropdown .dropdown-toggle::after {
                    position: absolute;
                    top: 20px;
                    right: 15px;
                    transform: rotate(0deg);
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .navbar-nav {
            .menu-item {
                &:hover {
                    .dropdown-menu {
                        transition: 0.3s;
                        opacity: 1;
                        visibility: visible;
                        transform: rotateX(0deg);
                    }
                }
            }
        }
    }
}

// 3rd Level Nav Desktop
@include media-breakpoint-up(xl) {
    .navbar-nav ul.dropdown-menu .dropdown ul.dropdown-menu {
        left: 100% !important;
        top: 0 !important;
        visibility: hidden;
        display: none;
        background: $brand-primary;

    }

    .navbar-nav ul.dropdown-menu .dropdown:hover ul.dropdown-menu {
        visibility: visible;
        display: block !important;
    }
}

// 3rd Level Nav Mobile
@include media-breakpoint-down(xl) {
    ul.dropdown-menu.show .dropdown ul.dropdown-menu {
        display: block;
    }
}

header.sticky-top.scrolling .header-default.navbar-light {
    .theme-logo {
        max-height: 85px;
        transition: none;

        @include media-breakpoint-up(md) {
            max-height: 115px;
        }
    }

    .navbar-toggler {
        @include media-breakpoint-up(md) {
            top: 32px;
        }
    }
}

.header-top .sub-heading {
    @include media-breakpoint-down(xs) {
        font-size: 12px;
    }
}

.rpb-mega-menu {
    @include media-breakpoint-up(lg) {
        min-height: 350px;
    }
}
