.tabs {
	.nav-item {
    background:#fff;
    border:0!important;
		a {
			opacity: 1 !important;
			color: $dark;
			font-size: 1rem !important;
			padding-right: 1em !important;
			width:100%;
			z-index:1;
			position:relative;
      letter-spacing:unset;
      transition:all .2s ease-out;
      @include media-breakpoint-up(lg) {
        padding-right: 3em !important;
        font-size: 1.25rem !important;
      }

			* {
        @include media-breakpoint-up(lg) {
          font-size: 1.25rem !important;
          margin-bottom: 0px;
        }
			}
		}

		.circle-icon {
			border: solid 1px $dark;
			background: transparent;
      position:relative;
			cursor: pointer;
			flex-grow: 0;
			flex-shrink: 0;
			right: 0;
			z-index:0;

			&:before {
				content: '+';
				color: $dark;
				position: absolute;
        //transform: translateY(-0.05em);
			}
		}

		&.active, &:hover {
			background:$light;
      color:$dark;
			.circle-icon {
				background: $dark;
        
				&:before {
					content: '-';
					//transform: translateY(-0.1em);
          color:#fff!important;
				}
			}
		}
    &:hover {
      a {
        transition:all .5s ease-out;
        letter-spacing:.0125em;
      }
    }
	}
  .tab-content {
    h1,.h1,h2,.h2,h3,.h3 {
      font-size:1.5em;
    }
  }
}
