body {
  background: white;
  overflow-x: hidden;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}

.main *::selection {
  color: $white;
  background: $brand-primary;
}


// Safari Fixes

// phone link
a[href^=tel] {
  text-decoration: inherit;
  color: inherit;
}

//aos
[data-aos*=flip] {
  -webkit-backface-visibility:hidden!important;
}

/** custom classes */

.notunder1500 {
  .btn {
    width: 100%;
    border-radius: 0!important;
  }
}

.background-fixed {
  @include media-breakpoint-up(lg) {
    background-attachment: fixed !important;
  }
}

.cursor-hover {
  &:hover {
    cursor:pointer;
  }
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.min-height {
  min-height: 66vw;

  @include media-breakpoint-up(xl) {
    min-height: 450px;
  }
}

hr {
  background-color:rgba(0,0,0,0.5);
}

.overflow-hidden {
  overflow: hidden!important;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
}

.grow-bkg {
  transition: all 0.2s ease-in;

  &.grow-this-bkg,
  .grow-this-bkg {
    background-size: 100%!important;
    transition: all 0.2s ease-in;

    &:hover {
      transition: all 0.2s ease-in;
      background-size: 110%!important;
    }
  }
}

.notunder1500 {
  display: none;
}
@media (min-width:1800px) {
  .notunder1500 {
    display: block;
  }
}

.negative-top {
  margin-top: -100px;
}

// Images

img {
  max-width: 100%;
}

// Util
.img-invert-white {
  filter: brightness(0) invert(1);
}

.grayscale {
  filter: grayscale(1);
}

.z-index-1 {
  z-index:1;
}
.z-index-2 {
  z-index:2;
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, $dark, darken($dark, 10%));
  opacity: 0.85;

  &.light-fade {
    background: linear-gradient(45deg, #000, lighten(#000,10%), #000);
    opacity: 0.6;
  }
}

.sticky {
  @include media-breakpoint-up(md) {
    position: sticky !important;
    top: 160px !important;
  }
}
// Content Images

.img-cover-col {
  background-size: cover;
  background-position: center;
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
// RESPONSIVE FIXES

.sticky-top {
  @include media-breakpoint-down(sm) {
    position: static;
  }
}

// Move accessibility button to bottom

.pojo-a11y-toolbar-toggle {
  bottom: 0;
}
