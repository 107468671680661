.sidebar {
  .module-padding {
    padding:1.5rem;
  }
  .module-mb {
    margin-bottom:2em;
  }
  .social-icon {
    background: $brand-primary;
    align-items: center;
    border-radius: 1.5em;
    display: inline-flex;
    height: 1.5em;
    justify-content: center;
    width: 1.5em;
    transform:scale(1);
    font-size: 1.25em !important;
    svg {
      color:#fff;
      font-size:.8em;
      &.icon {
        width: 1em;
        height: 1em;
        color: #fff; /* Example to change the color */
        fill: #fff; /* Makes the fill color inherit from text color */
      }
    }
    &:hover {
      background: $brand-primary;
      transform:scale(1.05);
      svg {
        color:#fff;
        &.icon {
          width: 1em;
          height: 1em;
          color: #fff; /* Example to change the color */
          fill: #fff; /* Makes the fill color inherit from text color */
        }
      }
    }
  }
  .sidebar-module {
    background:$light;
    padding:1.5rem;
    margin-bottom:2em;
    display:flex;
    flex-direction:column;
    .module-negative-top {
      margin-top:-1.5rem;
    }
    .ul.menu {
      padding-left:0px;
    }
    .circle-icon {
      display:flex;
      align-items:center;
      justify-content:center;
      background:$brand-primary;
      height: 2.5em;
      width: 2.5em;
      border-radius: 0em;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.25em;
      margin-bottom: 2.5rem;
      &.negative-top {
        margin-top:-2.5rem;
      }
      svg {
        color:#fff;
        fill:#fff;
      }
    }
  }
  .rpb-cards.bootstrap-cards-slider.arrows-bottom {
    margin-bottom: 0rem;
    .splide__arrow {
      bottom: 0rem !important;
      transform: scale(.75);
      &.splide__arrow--prev {
        left:calc(50% - 2em) !important;
        right:auto;
      }
      &.splide__arrow--next {
        right:calc(50% - 2em) !important;
        left:auto;
      }
    }
  }
  .widget {
    text-align: center;
    box-shadow: none;
    background: white;
    border-radius:$border-radius;
    margin-bottom: 2rem;
    &:last-child {
      border-bottom: 0px;
    }
    &.widget_categories select {
      width: 100%;
      padding: 1em;
      border-radius:0px;
      font-weight:bold;
      text-align:center;
      border:0;
      background:$light;
      &:hover, &:focus, &:active {
        outline: none;
        box-shadow: none;
      }
    }
    h3,
    .h3 {
      background:transparent;
      padding: .5em;
      margin-bottom:.5em;
    }
    .primary-btn {
      @include media-breakpoint-down(lg) {
        font-size: 13px;
        line-height: 1.5em;
      }
    }
    .gform_wrapper input,
    .gform_wrapper textarea,
    .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield select {
      background: #efefef;
      height: 30px;
      font-size: 13px !important;
      color: #888;
      border: solid 1px #ccc;
    }
    .gform_wrapper input[type="submit"] {
      background: $brand-primary !important;
      background: #00aeef;
      color: #fff;
      height: auto !important;
      border: 0px !important;
    }
    .boxed-header {
      padding: 15px;
      background: $brand-secondary;
      color: #fff !important;
    }
    li.gfield {
      margin-top: 5px;
      ::placeholder {
        color: #888;
      }
      input {
        border: solid 1px #ccc !important;
      }
    }
    li#field_2_2,
    li#field_2_1 {
      margin-top: 0px;
    }
    .menu {
      padding-left: 0px;
    }
    .textwidget {
      padding: 15px;
    }
  }
}
.contact .widget.gform_widget-2 {
  display: none !important;
}
.sidebar {
  margin-bottom: 0px;
  .menu {
    padding-left:0px;
    margin-bottom:0px;
    li {
      list-style: none;
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      font-size: 14px;
      text-transform: uppercase;
      font-family: $paragraph-font;
      padding: 0.75rem 0;
      &:last-child {
        border-bottom: 0;
      }
    } 
  }
  // Search bar
  .widget_search {
    background: transparent;
    box-shadow:none;
    .search-form {
      margin: 0;
      label {
        padding: 0;
      }
    }
  }
}
