// Default Slider Styles
.range-splider {
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow {
        background:white;
        opacity:1;
        svg {
          fill:$brand-primary;
          color:$brand-primary;
        }
      }
      .splide__arrow--prev {
        svg {
          transform:none;
        }
      }
      .splide__arrow--next {
        svg {
          transform:none;
        }
      }
    }
  }
  .splide__pagination {
    bottom: -2.5em;
    .splide__pagination__page {
      background:transparent;
      border:solid 1px $dark;
      transform:scale(1.5);
      margin:5px;
      &.is-active {
        background:$dark;
        border:solid 1px $dark;
        transform:scale(1.5);
      }
    }
  }
  &.arrows-end {
    margin-bottom: 2.25rem;
    .splide__arrow {
      font-size:1.5em;
      background:transparent!important;
      bottom:-6rem!important;
      top:auto;
      svg {
        color:$dark;
        fill:$dark;
      }
    }
    .splide__arrow--prev {
      left: calc(100% - 5em)!important;
      right: auto!important;
    }
    .splide__arrow--next {
      right: 0px!important;
      left: auto!important;
    }
    .splide__track {
      margin-right:0px;
      margin-left:0px;
    }
  }
  &.arrows-bottom {
    margin-bottom: 2.25rem;
    .splide__track {
      margin-left:0px!important;
      margin-right:0px!important;
    }
    .splide__arrow {
      font-size:1.5em;
      background:transparent!important;
      bottom:-6rem!important;
      top:auto;
      svg {
        color:$accent;
        fill:$accent;
      }
    }
    .splide__arrow--prev {
      left: calc(50% - 3em)!important;
      right: auto!important;
    }
    .splide__arrow--next {
      right: calc(50% - 3em)!important;
      left: auto!important;
    }
    .splide__track {
      margin-right:0px;
      margin-left:0px;
    }
  }
  .splide__pagination {
    .splide__pagination__page.is-active {
      background:$brand-primary;
    }
  }
  &.blurred-slides {
    .splide__slide {
      &:before {
        content:'';
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background:rgba(0,0,0,0.5);
        z-index: 1;
        backdrop-filter: saturate(180%) blur(2px);
        transition:all ease-in-out 350ms;
        opacity:1;
      }
      &.is-active {
        &:before {
          content:'';
          opacity:0;
        }
      }
    }
  }
}

// Column Content Slider
.range-content-slider {
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow--prev {
        left:0;
      }
      .splide__arrow--next {
        right:0;
      }
    }
    .splide__track {
      margin-left:3rem;
      margin-right:3rem;
    }
    .splide__pagination {
      bottom:-2.5rem;
    }
  }
}

// Coupon Slider Styles
.rpb-coupon-slider {
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow--prev {
        left:0;
      }
      .splide__arrow--next {
        right:0;
      }
    }
    .splide__track {
      margin-left:3rem;
      margin-right:3rem;
    }
    .splide__pagination {
      bottom:-2.5rem;
    }
  }
}

// Thumbnail Slider
.range-splider-thumbnails {
  .splide__track {
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    background:white;
    .splide__list {
      .splide__slide {
        opacity:0.3;
        &.is-active {
          border:0!important;
          opacity:1;
        }
      }
    }
  }
}

// Logo Banner Slider
.rpb-logo-slider {
  img {
    max-height:150px;
    max-width:auto;
  }
  .splide__slider {
    .rpb-slider-arrows {
      .splide__arrow--prev {
        left:0;
      }
      .splide__arrow--next {
        right:0;
      }
    }
    .splide__track {
      margin-left:3rem;
      margin-right:3rem;
    }
  }
}
