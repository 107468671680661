// Container Fluid Adjustments
.container-fluid {
  padding-left: 2rem;
  padding-right: 2rem;
  @include media-breakpoint-up(md) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include media-breakpoint-up(lg) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @include media-breakpoint-up(xxxl) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
.container {
  max-width:1200px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left:auto;
  margin-right:auto;
}
