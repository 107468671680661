.site-footer {
	img {
		width:auto;
		max-width:50%;
	}
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		li {
			padding-bottom:7.5px;
			&:last-of-type {
				padding-bottom:0;
			}
			a {
				color: $dark;
				text-decoration: none;
				font-weight: normal;
			}
		}
	}	
	&.background-white, &.background-light {
		ul {
			li {
				a {
					color: $dark;
				}
			}
		}	
		.social-icon {
			background: rgba(0,0,0,.1);
			align-items: center;
			border-radius: 1.5em;
			display: inline-flex;
			height: 1.5em;
			justify-content: center;
			width: 1.5em;
			transform:scale(1);
			svg {
				color:$brand-primary;
				font-size:.8em;
				&.icon {
					width: 1em;
					height: 1em;
					color: $brand-primary; /* Example to change the color */
					fill: $brand-primary; /* Makes the fill color inherit from text color */
				}
			}
			&:hover {
				background: rgba(0,0,0,.1);
				transform:scale(1.05);
				svg {
					color:$brand-primary;
					&.icon {
						width: 1em;
						height: 1em;
						color: $brand-primary; /* Example to change the color */
						fill: $brand-primary; /* Makes the fill color inherit from text color */
					}
				}
			}
		}
	}
	&.background-dark {
		ul {
			li {
				a {
					color: #fff;
				}
			}
		}	
		.social-icon {
			background: rgba(255,255,255,.1);
			align-items: center;
			border-radius: 1.5em;
			display: inline-flex;
			height: 1.5em;
			justify-content: center;
			width: 1.5em;
			transform:scale(1);
			svg {
				color:#fff;
				font-size:.8em;
				&.icon {
					width: 1em;
					height: 1em;
					color: #fff; /* Example to change the color */
					fill: #fff; /* Makes the fill color inherit from text color */
				}
			}
			&:hover {
				background: rgba(255,255,255,.1);
				transform:scale(1.05);
				svg {
					color:#fff;
					&.icon {
						width: 1em;
						height: 1em;
						color: #fff; /* Example to change the color */
						fill: #fff; /* Makes the fill color inherit from text color */
					}
				}
			}
		}
	}
	.gform_wrapper {
		.gform_description {
			margin-bottom:1rem;
			display:block;
		}
		.gfield_required_text {
			color:white;
		}
	}
	.gform_wrapper.gravity-theme .gform_footer button, .gform_wrapper.gravity-theme .gform_footer input, .gform_wrapper.gravity-theme .gform_page_footer button, .gform_wrapper.gravity-theme .gform_page_footer input {
		background:white;
		border:4px solid white;
		color:$brand-primary;
		&:hover {
			background:lighten($brand-primary,15%);
			border:4px solid lighten($brand-primary,15%);
			color:white;
		}
	}
}

.footer-main {
	p {
		margin-bottom:15px;
	}
}

.footer-dark {
	ul {
		li {
			a {
				color:white;
				&:hover {
					color:white;
					opacity:0.8;
				}
			}
		}
	}
}

.copyright-dark {
	background:darken($dark, 10%);
	a {
		color:white;
		&:hover {
			color:white;
			opacity:0.8;
		}
	}
}
