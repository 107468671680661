.image-collage-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1.25rem;
  min-height: 75vh;
}

.collage-item {
  background-position: center;
  background-size: cover;
  height: 100%;
  cursor: pointer;
  min-height: 15rem;

  @include media-breakpoint-down(sm) {
    grid-column: span 6 !important;
    grid-row: span 1 !important;
    min-height: 10rem;
  }
  &:hover {
    transform: scale(1.05);
    @extend .shadow-lg;
  }
}